<template>
  <nav class="flex flex-row justify-between bg-gray-900 mx-4 rounded-full mb-4 py-3 px-5 mt-10 items-center">
    <img class="h-6" src="@/assets/olympus-logo.png" alt="Logo" />
    <div class="flex flex-row space-x-4">
      <a href="https://mobile.twitter.com/olympus_token" target="_blank"
        ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-locker_primary fill-current transition-opacity duration-200 opacity-100 hover:opacity-70" fill="none" viewBox="0 0 30 30">
          <path
            d="M28 6.937c-.957.425-1.985.711-3.064.84a5.343 5.343 0 002.345-2.951 10.696 10.696 0 01-3.388 1.295 5.334 5.334 0 00-9.089 4.864A15.143 15.143 0 013.809 5.411a5.321 5.321 0 00-.721 2.683 5.33 5.33 0 002.372 4.439 5.323 5.323 0 01-2.416-.667v.067a5.335 5.335 0 004.279 5.23 5.336 5.336 0 01-2.409.092 5.34 5.34 0 004.983 3.705 10.699 10.699 0 01-6.625 2.284c-.43 0-.855-.025-1.273-.075a15.102 15.102 0 008.177 2.396c9.812 0 15.176-8.128 15.176-15.177 0-.231-.005-.461-.015-.69A10.855 10.855 0 0028 6.937z"
          />
        </svg>
      </a>
      <a href="https://www.instagram.com/olympusbsc/?hl=en" target="_blank"
        ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-locker_primary fill-current transition-opacity duration-200 opacity-100 hover:opacity-70" fill="none" viewBox="0 0 30 30">
          <path
            d="M9.998 3C6.139 3 3 6.142 3 10.002v10C3 23.861 6.142 27 10.002 27h10C23.861 27 27 23.858 27 19.998v-10C27 6.139 23.858 3 19.998 3h-10zM22 7a1 1 0 110 2 1 1 0 010-2zm-7 2c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6zm0 2a4 4 0 100 8 4 4 0 000-8z"
          />
        </svg>
      </a>
      <a href="https://t.me/OlympusOfficial" target="_blank"
        ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-locker_primary fill-current transition-opacity duration-200 opacity-100 hover:opacity-70" fill="none" viewBox="0 0 30 30">
          <path
            d="M25.154 3.984a2.718 2.718 0 00-.894.217c-.25.1-1.204.51-2.707 1.154-1.505.646-3.497 1.5-5.621 2.414L4.457 12.707c-.092.04-.413.142-.754.408-.34.266-.703.817-.703 1.432 0 .495.236.987.533 1.281.297.294.612.439.881.549l4.58 1.873c.202.617 1.299 3.973 1.553 4.795.168.543.327.882.535 1.152.104.135.225.253.371.346.059.037.123.065.188.092l.004.002c.014.006.027.016.043.021.028.01.047.011.085.02.153.049.306.08.444.08.585 0 .943-.322.943-.322l.022-.016 3.01-2.604 3.65 3.454c.051.072.53.73 1.588.73.627 0 1.125-.315 1.445-.65.32-.336.519-.688.604-1.131v-.002c.079-.419 3.443-17.69 3.443-17.69l-.006.024c.099-.45.124-.868.016-1.282a1.747 1.747 0 00-.75-1.021 1.797 1.797 0 00-1.028-.264zm-.187 2.09c-.005.03.003.015-.004.049l-.002.012-.002.011s-3.322 17.05-3.445 17.7c.009-.05-.032.048-.075.107-.06-.041-.181-.094-.181-.094l-.02-.021-4.986-4.717-3.525 3.047 1.048-4.2s6.557-6.786 6.952-7.181c.318-.316.384-.426.384-.535 0-.146-.076-.252-.246-.252-.153 0-.358.149-.468.219-1.434.913-7.725 4.58-10.545 6.22-.449-.183-3.562-1.458-4.618-1.888l.014-.006 11.473-4.938c2.124-.913 4.118-1.77 5.62-2.414 1.48-.634 2.51-1.071 2.626-1.119z"
          />
        </svg>
      </a>
      <a href="mailto:contact@olympustoken.io" target="_blank"
        ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-locker_primary fill-current transition-opacity duration-200 opacity-100 hover:opacity-70" fill="none" viewBox="0 0 30 30">
          <path d="M2.537 6L13.81 16.521a1.746 1.746 0 002.38 0L27.462 6H2.537zM1 7.3V24h28V7.3L17.555 17.983A3.735 3.735 0 0115 18.986a3.735 3.735 0 01-2.555-1.004L1 7.301z" />
        </svg>
      </a>
    </div>
  </nav>
</template>

<script>
// @ is an alias to /src
// import ItemComp from "@/components/Itemc.vue";
export default {
  name: "Footer",
};
</script>
