<template>
  <div class="wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.4s">
    <router-link to="/explore" class="flex items-center max-w-125 bg-gray-800 border border-gray-700 mx-auto place-content-center p-4 rounded-t-2xl">
      <div class="flex flex-row opacity-50 hover:opacity-100 transition-opacity duration-200">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
        <span class="ml-2 overline">Go Back</span>
      </div>
    </router-link>
    <div class="bg-gray-900 p-4 border border-gray-700 max-w-125 border-t-0 rounded-b-2xl mx-auto">
      <div class="text-center flex flex-col items-center">
        <h3 class="my-4">Lock Overview</h3>
        <div class="h-48 w-48 flex flex-col rounded-full items-center text-center bg-gradient-to-b from-locker_primary to-locker_primary-dark shadow-locker_primary place-content-center space-y-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-locker_primary-xtra_dark" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
          </svg>
          <span class="font-heading text-4xl font-bold">45%</span>
          <span class="uppercase text-xs font-bold text-locker_primary-xtra_dark">Locked</span>
        </div>
        <div class="my-6 space-y-2 w-full">
          <div class="flex flex-row items-center justify-between">
            <span class="caption text-gray-300">Locked by</span>
            <div class="flex flex-row items-center space-x-2">
              <span class="caption text-gray-400">0x9d9AFFAc21...</span>
              <a href="">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg
              ></a>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
             <span class="caption text-gray-300">Tx</span>
            <div class="flex flex-row items-center space-x-2">
              <span class="caption text-gray-400">0x9d9AFFAc21...</span>
              <a href="">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="space-y-2">
        <div class="flex flex-row justify-between">
          <span class="overline">Locked Until</span>
          <div class="gradient-text">12:41, 25th of May 2026</div>
        </div>
        <div class="flex flex-row justify-between">
          <span class="caption text-gray-300">Lock Value</span>
          <div class="overline">$113.09K</div>
        </div>
        <div class="flex flex-row justify-between">
          <span class="caption text-gray-300">Liquidity Locked</span>
          <div class="overline">$113.09K</div>
        </div>
        <div class="flex flex-row justify-between">
          <span class="caption text-gray-300">Circulating Supply</span>
          <div class="overline">$113.09K</div>
        </div>
        <div class="flex flex-row justify-between">
          <span class="caption text-gray-300">Total Supply</span>
          <div class="overline">$113.09K</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import ItemComp from "@/components/Itemc.vue"

export default {
  name: "Card",
};
</script>

<style scoped>
.card {
  background-color: #081a2e;
  border-radius: 16px;
  padding: 20px 40px;
  max-width: 550px;
  margin: auto;
}
</style>
