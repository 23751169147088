<template>
  <section style="margin-top:0px" class="container relative">
    <div class="tokenrow flex items-center place-content-center">
      <ul class="flex flex-col lg:flex-row">
        <button
          v-wave
          :class="[lockMode ? modeInactive : modeActive]"
          @click="lockMode = 0"
          class="rounded-full lg:w-auto w-full lg:order-1 order-2 hover:opacity-80 transition-all duration-200 flex flex-row place-self-center py-3 px-4 border-2 wow fadeInLeft"
          data-wow-duration="0.3s"
          data-wow-delay="0.4s"
        >
          <span class="font-semibold text-center flex flex-row items-center w-full">
            <div class="bg-gray-900 bg-opacity-20 rounded-full p-2 mr-5">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 fill-current" fill="none" viewBox="0 0 48 48">
                <path
                  d="M16.5 3c-1.893 0-3.297.272-4.254.566-.957.295-1.578.686-1.578.686a1.5 1.5 0 00-.23 2.307s3.462 3.647 3.548 7.77c-.021 1.407-.452 2.77-1.279 3.806-1.738 2.176-2.898 4.955-3.41 7.789A16.116 16.116 0 009 29.129v.004a19 19 0 00.78 5.205v.002c1.27 4.35 3.729 7.552 5.142 9.148l.004.002.002.004A4.453 4.453 0 0018.279 45h11.442a4.453 4.453 0 003.355-1.512h.002c1.421-1.594 3.873-4.8 5.143-9.148.474-1.628.779-3.42.779-5.34 0-1.014-.099-2.044-.295-3.07l-.002-.004a18.632 18.632 0 00-.717-2.705c.01-.073.015-.147.014-.221 0-.125.094-.424.477-.928.382-.504.995-1.14 1.656-1.9C41.453 18.653 43 16.5 43 13.5 43 9.376 39.624 6 35.5 6h-3c-4.073 0-6.448-.69-8.65-1.424C21.647 3.842 19.523 3 16.5 3zm0 3c2.477 0 4.103.658 6.4 1.424 1.99.663 4.612 1.266 8.1 1.441V13H20.5a1.5 1.5 0 100 3h10.762c.252 1.46.723 2.883 1.678 4.076h-.002c1.09 1.364 1.955 3.136 2.48 4.983A3.987 3.987 0 0032 29c0 2.033 1.52 3.691 3.482 3.947-.047.185-.09.372-.142.553-1.088 3.726-3.29 6.634-4.51 8.002l-.01.01a1.471 1.471 0 01-1.1.488H18.28c-.427 0-.835-.185-1.1-.488l-.008-.006c-1.227-1.385-3.424-4.28-4.512-8.004V33.5c-.053-.181-.095-.368-.142-.553C14.48 32.691 16 31.033 16 29a3.986 3.986 0 00-3.426-3.941c.524-1.888 1.389-3.689 2.479-5.053 1.308-1.638 1.914-3.666 1.937-5.668v-.045c-.069-3.776-1.676-6.314-2.965-7.975C14.687 6.172 15.32 6 16.5 6zM34 9h1.5c2.504 0 4.5 1.996 4.5 4.5 0 2-.953 3.347-2.133 4.703-.476.547-.974 1.082-1.443 1.649a15.414 15.414 0 00-1.143-1.649c-.815-1.018-1.235-2.355-1.261-3.742a1.506 1.506 0 00-.02-.201V9zM24 25a4 4 0 100 8 4 4 0 000-8z"
                />
              </svg>
            </div>
            <span class="pr-2">Liquidity</span>
          </span>
        </button>

        <div class="max-w-75 lg:order-2 order-1 wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.4s">
          <Lionanimate />
        </div>

        <button
          v-wave
          :class="[lockMode ? modeActive : modeInactive]"
          @click="lockMode = 1"
          class="rounded-full lg:mt-0 mt-4 lg:w-auto w-full lg:order-3 order-3 hover:opacity-80 transition-all duration-200 flex flex-row place-self-center py-3 px-4 border-2 wow fadeInRight"
          data-wow-duration="0.3s"
          data-wow-delay="0.4s"
        >
          <span class="font-semibold text-center flex flex-row items-center w-full">
            <div class="bg-gray-900 bg-opacity-20 rounded-full p-2 mr-5">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 fill-current" fill="none" viewBox="0 0 48 48">
                <path
                  d="M30.5 4c-4.708 0-8.86 2.427-11.273 6.094C10.686 10.984 4 18.228 4 27c0 9.371 7.629 17 17 17 8.772 0 16.016-6.686 16.906-15.227C41.573 26.36 44 22.208 44 17.5 44 10.062 37.938 4 30.5 4zm0 3C36.316 7 41 11.684 41 17.5c0 2.933-1.2 5.571-3.133 7.47-.928-7.749-7.088-13.908-14.838-14.837A10.43 10.43 0 0130.5 7zM21 13c7.75 0 14 6.25 14 14 0 .258-.025.51-.04.764a1.493 1.493 0 00-.005.127C34.495 35.22 28.45 41 21 41c-7.75 0-14-6.25-14-14 0-7.44 5.766-13.481 13.084-13.953.061 0 .123-.003.184-.01.244-.013.485-.037.732-.037zm-2.5 6a1.5 1.5 0 00-1.47 1.205l-1 5A1.5 1.5 0 0017.5 27h4c1.398 0 2.5 1.102 2.5 2.5 0 .737-.246 1.306-.703 1.738C22.84 31.67 22.126 32 21 32c-2.404 0-3.75-.799-3.75-.799a1.5 1.5 0 10-1.5 2.598S17.872 35 21 35c1.749 0 3.285-.566 4.36-1.582C26.433 32.402 27 30.972 27 29.5c0-3.02-2.48-5.5-5.5-5.5h-2.17l.4-2h4.77a1.5 1.5 0 100-3h-6z"
                />
              </svg>
            </div>
            <span class="pr-2">Tokens</span>
          </span>
        </button>
      </ul>
    </div>

    <div v-if="false" class="w-full lg:mt-o mt-4 bg-gray-900 border border-gray-700 p-4 items-center rounded-2xl overflow-hidden flex flex-col wow fadeInDown mb-4" data-wow-duration="0.3s" data-wow-delay="0.6s">
      <h3 class="gradient-text">Filter by Token</h3>
      <input
        class="bg-locker_primary overline bg-opacity-10 hover:shadow-locker_primary focus:shadow-locker_primary relative filterByToken mt-4 border-locker_primary transition-all duration-200 placeholder-locker_primary-dark text-locker_primary rounded-full border p-3 w-full text-center"
        placeholder="Enter paid address or token name"
      />
    </div>

    <div class="relative w-full h-164 mb-10 wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.7s">
      <transition name="fadeFromBottom">
        <div v-if="lockMode === 0" class="absolute w-full" id="lockedliquidity">
          <div class="bg-gray-900 border border-gray-700 rounded-2xl overflow-hidden">
            <div class="bg-gray-800 shadow-lg border-b border-gray-700">
              <div class="flex-row flex justify-between h-12 pr-9 pl-6 items-center">
                <div class="lg:flex hidden overline text-gray-400 tokenName">Name</div>
                <div class="lg:flex hidden overline text-gray-400 liqLocked">Supply Locked</div>
                <div class="lg:flex hidden overline text-gray-400 valueLocked">Locked Value</div>
                <div class="lg:flex hidden overline text-gray-400 nextUnlock">Unlock</div>
                <div class="w-full flex justify-end actionCol" v-if="false">
                  <Popper placement="bottom">
                    <button class="rounded-md text-locker_primary font-semibold bg-locker_primary hover:bg-opacity-30 transition-all duration-200 bg-opacity-20 py-1 px-3 flex flex-row space-x-3 items-center">
                      Show
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    <template #content="{ close }">
                      <div class="bg-gray-700 border rounded-md shadow-lg border-gray-600 flex flex-col items-start">
                        <button class="hover:opacity-50 p-3 w-full text-left transition-opacity duration-200" @click="close">All</button>
                        <button class="hover:opacity-50 p-3 border-t border-b border-gray-500 w-full text-left transition-opacity duration-200" @click="close">Locked</button>
                        <button class="hover:opacity-50 p-3 w-full text-left transition-opacity duration-200" @click="close">Unlocked</button>
                      </div>
                    </template>
                  </Popper>
                </div>
              </div>
            </div>
            <div class="h-154 overflow-auto lg:space-y-8 space-y-6 px-2 py-6 lg:py-6 lg:px-6">
              <div v-for="lock in $store.getters['locks/allLocks']">
                <Rowsingle v-if="$store.getters['locks/isLoaded'](lock)" :lock="lock" lockType="LP"/>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fadeFromBottom">
        <div v-if="lockMode === 1" class="absolute w-full" id="lockedtokens">
          <div class="bg-gray-900 border border-gray-700 rounded-2xl overflow-hidden">
            <div class="bg-gray-800 shadow-lg border-b border-gray-700">
              <div class="flex-row flex justify-between h-12 pr-9 pl-6 items-center">
                <div class="lg:flex hidden overline text-gray-400 tokenName">Name</div>
                <div class="lg:flex hidden overline text-gray-400 liqLocked">Supply Locked</div>
                <div class="lg:flex hidden overline text-gray-400 valueLocked">Locked Value</div>
                <div class="lg:flex hidden overline text-gray-400 nextUnlock">Unlock</div>
                <div class="w-full flex justify-end actionCol" v-if="false">
                  <Popper placement="bottom">
                    <button class="rounded-md text-locker_primary font-semibold bg-locker_primary hover:bg-opacity-30 transition-all duration-200 bg-opacity-20 py-1 px-3 flex flex-row space-x-3 items-center">
                      Show
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    <template #content="{ close }">
                      <div class="bg-gray-700 border rounded-md shadow-lg border-gray-600 flex flex-col items-start">
                        <button class="hover:opacity-50 p-3 w-full text-left transition-opacity duration-200" @click="close">All</button>
                        <button class="hover:opacity-50 p-3 border-t border-b border-gray-500 w-full text-left transition-opacity duration-200" @click="close">Locked</button>
                        <button class="hover:opacity-50 p-3 w-full text-left transition-opacity duration-200" @click="close">Unlocked</button>
                      </div>
                    </template>
                  </Popper>
                </div>
              </div>
            </div>
            <div class="h-154 overflow-auto lg:space-y-8 space-y-6 px-2 py-6 lg:py-6 lg:px-6">
              <div v-for="lock in $store.getters['tokenLocks/allLocks']">
                <Rowsingle v-if="$store.getters['tokenLocks/isLoaded'](lock)" :lock="lock" lockType="TOKEN"/>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Rowsingle from "./Rowsingle.vue";
import Lionanimate from "@/components/Lionanimate.vue";
import Popper from "vue3-popper";

export default {
  name: "Rows",
  data: function() {
    return {
      lockMode: 0,
      modeActive: "bg-locker_primary shadow-locker_primary border-locker_primary text-locker_primary-xtra_dark",
      modeInactive: "bg-gray-900 text-gray-400 border-gray-600",
    };
  },
  components: {
    Rowsingle,
    Lionanimate,
    Popper,
  },
};
</script>
