<template>
  <div>
    <div class="heading wow fadeInDown mt-5" data-wow-duration="0.6s" data-wow-delay="0.3s">
      <h2 class="pb-2">
        Token Locker
      </h2>
      <div class="lion">
        <Lionanimate />
      </div>
    </div>
    <LockerBox lockType="TOKEN"/>
    <div class="container flex lg:flex-row flex-col wow fadeInDown lg:space-y-0 space-y-4 lg:space-x-4 wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.9s">
      <Lockers name="Lock liquidity" btnname="LOCK" routername="liquiditylocker" />
      <Lockers name="Lock tokens" btnname="LOCK" routername="tokenlocker" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LockerBox from "@/views/Liquiditylocker/components/LockerBox"
import Lockers from "@/components/Lockers.vue";
import Lionanimate from "@/components/Lionanimate.vue";
export default {
  name: "Mylocker",
  components: {
    LockerBox,
    Lockers,
    Lionanimate,
  },
};
</script>

<style scoped>
.lion {
  max-width: 150px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: -36px;
  display: block;
}
</style>
