<template>
  <div class="flex lg:flex-row flex-col justify-between items-center lg:space-y-0 space-y-4 lg:bg-transparent bg-gray-800 lg:p-0 p-4 rounded-lg lg:mx-0 mx-4">
    <div class="tokenNameCell lg:pb-0 pb-4 flex items-center">
      <div class="relative flex-none h-6 w-10 items-center">
        <img class="w-6 h-6 absolute border-locker_primary border-2 rounded-full" src="@/assets/icons/olympus.svg" alt="Logo" />
        <img class="w-6 h-6 absolute left-4 shadow-smooth z-10 border-locker_primary border-2 rounded-full" src="@/assets/icons/bnb.svg" alt="Logo" />
      </div>
      <span class="text-sm inline-block ml-2">OLYMPUS / BNB</span>
    </div>

    <div class="text-sm justify-between w-full flex liqLockedCell">
      <span class="lg:hidden inline-block text-gray-400 pr-2">Liquidity locked</span><span>20.01M<span class="text-locker_primary pl-2">(20.10%)</span></span>
    </div>
    <div class="text-sm justify-between w-full flex tokenPriceCell"><span class="lg:hidden inline-block text-gray-400 pr-2">Locked</span><span>02/02/21</span></div>
    <div class="text-sm justify-between w-full flex valueLockedCell"><span class="lg:hidden inline-block text-gray-400 pr-2">Unlocks</span><span>02/02/22</span></div>
    <div class="text-sm justify-between w-full flex nextUnlockCell">
      <span class="lg:hidden inline-block text-gray-400 pr-2">Next Unlock</span>
      <div class="countdown w-full caption">
        <div class="time text-gray-400 caption uppercase">
          <Timer
            starttime="Nov 5, 2021 15:37:25"
            endtime="Nov 8, 2022 16:37:25"
            trans='{  
         "day":"D",
         "hours":"h",
         "minutes":"m",
         "expired":"Expired.",
         "status": {
            "expired":"Expired",
            "running":"Running",
            "upcoming":"Future"
           }}'
          ></Timer>
        </div>
        <div class="h-2 bg-gray-700 rounded-full w-full">
          <div class="w-full rounded-full bg-gradient-to-r to-locker_primary-dark h-full from-locker_primary" style="width:35%"></div>
        </div>
      </div>
    </div>
    <div class="text-sm actionColCell hover:opacity-50 transition-opacity duration-200 text-locker_primary justify-end flex items-center"><button>View Tx</button></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import ItemComp from "@/components/Itemc.vue"
import Timer from "@/components/Timer.vue";
export default {
  name: "Rowsingle",
  components: {
    Timer,
  },
};
</script>

<style scoped>

</style>
