<template>
  <div>
    <Head/>
    <Rows v-if="$store.getters['wallet/isWalletConnected']"/>
    <div class=" h-142 overflow-auto lg:space-y-8 space-y-6 px-2 py-6 lg:py-6 lg:px-6" v-else>
      <div class="sm:px-8 px-4 py-8 flex flex-col items-center">
        <Button name="Connect Wallet" class="connectBtn w-full max-w-64"
                @click="$store.dispatch('wallet/connectWallet')"
        />
      </div>
    </div>
    <div class="container flex lg:flex-row flex-col wow fadeInDown lg:space-y-0 space-y-4 lg:space-x-4" data-wow-duration="0.6s" data-wow-delay="0.9s">
      <Lockers name="Lock liquidity" btnname="LOCK" routername="liquiditylocker" />
      <Lockers name="Lock tokens" btnname="LOCK" routername="tokenlocker" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Button from "@/components/Button.vue";
import Rows from "./components/Rows.vue";
import Head from "./components/Head.vue";
import Lockers from "@/components/Lockers.vue";
export default {
  name: "Mylocks",
  components: {
    Rows,
    Head,
    Lockers,
    Button
  },
};
</script>

<style>

</style>
