<template>
  <router-link class="w-full hover:-translate-y-1 duration-500 ease-in-out transform rounded-2xl overflow-hidden shadow-locker_primary" :to="{ path: '/' + routername }">
    <div class="bg-gray-900 text-center flex place-content-center border-2 border-locker_primary rounded-2xl w-full p-4 lg:p-8">
      <div class="flex flex-row w-full justify-between lg:flex-col items-center">
        <p class="text-lg text-white font-bold">{{ name }}</p>
        <span class="lg:mt-4 text-lg text-white font-bold py-2 px-3 lg:py-2 lg:px-4 bg-locker_primary bg-opacity-20 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" class="fill-current text-locker_primary w-5 h-5 lg:w-8 lg:h-8">
            <path
              d="M 22.990234 8.9902344 A 1.0001 1.0001 0 0 0 22.292969 10.707031 L 25.585938 14 L 2 14 A 1.0001 1.0001 0 1 0 2 16 L 25.585938 16 L 22.292969 19.292969 A 1.0001 1.0001 0 1 0 23.707031 20.707031 L 28.619141 15.794922 A 1.0001 1.0001 0 0 0 28.623047 14.207031 A 1.0001 1.0001 0 0 0 28.617188 14.203125 L 23.707031 9.2929688 A 1.0001 1.0001 0 0 0 22.990234 8.9902344 z"
            />
          </svg>
        </span>
      </div>
    </div>
  </router-link>
</template>

<script>
// @ is an alias to /src
// import ItemComp from "@/components/Itemc.vue";
export default {
  name: "Lockers",
  props: {
    name: String,
    btnname: String,
    routername: String,
  },
};
</script>

<style>
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #1973bc;
  }
}
</style>
