<template>
  <section class="container" style="margin-bottom:20px">
    <div class="heading wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0s">
      <h2 class="pb-2">
        Explore All Locks
      </h2>
      <span class="overline text-locker_primary">Select Lock Type</span>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import ItemComp from "@/components/Itemc.vue"

export default {
  name: "Head",
};
</script>

<style scoped></style>
