<template>
  <section>
    <div class="wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0s">
      <div class="flex flex-col items-center mb-6">
        <div class="relative flex-none h-12 w-20 items-center">
          <img class="w-12 h-12 absolute border-locker_primary border-2 rounded-full" src="@/assets/icons/olympus.svg" alt="Logo" />
          <img class="w-12 h-12 absolute left-8 shadow-smooth z-10 border-locker_primary border-2 rounded-full" src="@/assets/icons/bnb.svg" alt="Logo" />
        </div>
        <span class="text-lg font-bold inline-block pt-3">{{  name }}</span>
      </div>
    </div>
    <div class="wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.4s">
      <router-link to="/explore" class="flex items-center max-w-125 bg-gray-800 border border-gray-700 mx-auto place-content-center p-4 rounded-t-2xl">
        <div class="flex flex-row opacity-50 hover:opacity-100 transition-opacity duration-200">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
          <span class="ml-2 overline">Go Back</span>
        </div>
      </router-link>
      <div class="bg-gray-900 p-4 border border-gray-700 max-w-125 border-t-0 rounded-b-2xl mx-auto">
        <div class="text-center flex flex-col items-center">
          <h3 class="my-4">Lock Overview</h3>
          <div class="h-48 w-48 flex flex-col rounded-full items-center text-center bg-gradient-to-b from-locker_primary to-locker_primary-dark shadow-locker_primary place-content-center space-y-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-locker_primary-xtra_dark" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
            <span class="font-heading text-4xl font-bold">{{ fullyUnlocked ? "Unlocked" : supplyLocked }}</span>
            <span class="uppercase text-xs font-bold text-locker_primary-xtra_dark" v-if="!fullyUnlocked">Supply Locked</span>
          </div>
          <div class="my-6 space-y-2 w-full">
            <div class="flex flex-row items-center justify-between">
              <span class="caption text-gray-300">Locked Token</span>
              <div class="flex flex-row items-center space-x-2">
                <a :href="'https://bscscan.com/token/'+token" class="flex" rel="noopener noreferrer" target="_blank">
                  <span class="caption text-gray-400 mr-1">{{ token.substr(0,8) }}...</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg
                  ></a>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <span class="caption text-gray-300">Locked by</span>
              <div class="flex flex-row items-center space-x-2">
                <a :href="'https://bscscan.com/address/'+owner" class="flex" rel="noopener noreferrer" target="_blank">
                  <span class="caption text-gray-400 mr-1">{{ owner.substr(0,8) }}...</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg
                  ></a>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <span class="caption text-gray-300">Lock Holders</span>
              <div class="flex flex-row items-center space-x-2">
                <a :href="'https://bscscan.com/token/'+lock+'#balances'" class="flex" rel="noopener noreferrer" target="_blank">
                  <span class="caption text-gray-400 mr-1">View on BscScan</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg
                  ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="space-y-2" v-if="!fullyUnlocked">
          <div class="flex flex-row justify-between">
            <span class="overline">Locked Until</span>
            <div class="gradient-text">{{ unlock }}</div>
          </div>
          <div class="flex flex-row justify-between">
            <span class="caption text-gray-300">Lock Amount</span>
            <div class="overline">{{ amountLocked.toFixed(2) }} {{ symbol }}</div>
          </div>
          <div class="flex flex-row justify-between">
            <span class="caption text-gray-300">Lock Value</span>
            <div class="overline">${{ value.toFixed(2) }}</div>
          </div>
          <div class="flex flex-row justify-between">
            <span class="caption text-gray-300">Total Supply</span>
            <div class="overline">{{ supply }}</div>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row mt-4 lg:space-y-0 lg:space-x-4" v-if="canUnlock">
          <button v-wave @click="sendUnlock" class="p-6 bg-locker_primary text-gray-900 rounded-lg w-full font-semibold hover:bg-opacity-80 transition-all duration-200">
            Unlock
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Card from "./Card.vue";
import {canUnlock, getLockFactory, unlock} from "@/js/locker";
import {FACTORY_ADR_LP} from "@/js/constants";
export default {
  name: "Head",
  components: {
    Card,
  },
  props: ["lock"],
  data(){
    return {
      lockType: 0,

      fullyUnlocked: false,

      token: "",
      owner: "",
      name: "",
      symbol: "",
      unlock: "",
      value: 0,
      supply: 0,
      supplyLocked: 0,
      amountLocked: 0,
      canUnlock: false,
    }
  },
  created(){
    Promise.resolve(this.$store.state.initialization).then(async ()=>{
      this.loadLock();
    })
  },
  watch: {
    lock: async function(){
      this.loadLock();
    },
    wallet: async function(){
      this.canUnlock = await canUnlock(this.$store.getters["wallet/web3"], this.lock, this.$store.getters["wallet/address"]);
    }
  },
  computed: {
    wallet(){
      return this.$store.getters["wallet/address"];
    }
  },
  methods: {
    async loadLock(){
      let factory = await getLockFactory(this.$store.state.web3, this.lock);
      if(factory === FACTORY_ADR_LP){
        await this.$store.dispatch("locks/loadLock", this.lock);
        let info = this.$store.getters["locks/info"](this.lock);
        this.owner = info.info.owner;
        this.token = info.pairInfo.pair;
        this.value = parseFloat(info.lockedValue);
        this.name = info.token0.symbol + " / " + info.token1.symbol;
        this.symbol = "LP";
        this.supply = (info.pairInfo.supply / Math.pow(10, 18)).toFixed(0);
        this.supplyLocked = info.supplyLocked;
        this.amountLocked = parseFloat(info.amountLocked);
        this.fullyUnlocked = this.amountLocked == 0;
        this.unlock = info.unlocked ? "Now Unlocked" : this.getUnlockDate(info.timeLeft.totalSeconds) + " (" + info.timePercentThrough + ")";

        if(this.$store.getters["wallet/isWalletConnected"]){
          this.canUnlock = await canUnlock(this.$store.getters["wallet/web3"], this.lock, this.$store.getters["wallet/address"]);
        }
      }else{
        this.$store.dispatch("tokenLocks/loadLock", this.lock);
        let info = this.$store.getters["tokenLocks/info"](this.lock);
        this.owner = info.info.owner;
        this.token = info.token.adr;
        this.value = parseFloat(info.lockedValue);
        this.name = info.token.name;
        this.symbol = info.token.symbol;
        this.supply = (info.token.supply / Math.pow(10, info.token.decimals)).toFixed(0);
        this.supplyLocked = info.supplyLocked;
        this.amountLocked = parseFloat(info.amountLocked);
        this.fullyUnlocked = this.amountLocked == 0;
        this.unlock = info.unlocked ? "Now Unlocked" : this.getUnlockDate(info.timeLeft.totalSeconds) + " (" + info.timePercentThrough + ")";

        if(this.$store.getters["wallet/isWalletConnected"]){
          this.canUnlock = await canUnlock(this.$store.getters["wallet/web3"], this.lock, this.$store.getters["wallet/address"]);
        }
      }
    },
    getUnlockDate(timestamp){
      let date = new Date(Date.now() + (timestamp * 1000));
      let mins = date.getMinutes().toString();
      if(mins === "0"){ mins = "00"; }
      return date.getFullYear()+
          "-"+(date.getMonth()+1)+
          "-"+date.getDate()+
          " "+date.getHours()+
          ":"+mins;
    },
    async sendUnlock(){
      await unlock(this.$store.getters["wallet/web3"], this.lock, this.$store.getters["wallet/address"])
      this.loadLock();
    }
  }
};
</script>

<style scoped></style>
