import { createStore } from "vuex";

import wallet from "@/store/wallet.js";
import locks from "@/store/locks.js";
import Web3 from "web3";
import {getAggregateInfo, getAllLocks, loadLPLockInfo} from "@/js/locker";
import {RPC} from "@/js/constants";
import {getBNBPrice} from "@/js/utilities";
import tokenLocks from "@/store/tokenLocks";

export default createStore({
    modules: {
        wallet,
        locks,
        tokenLocks,
    },
    state: {
        initialization: null,

        totalLocks: "-",
        totalTime: "-",

        web3: null,
        bnbPrice: null,
    },
    mutations: {
        initialize(state, initialization){
            state.initialization = initialization;
        }
    },
    actions: {
        async initialize(context){
            let provider = new Web3.providers.HttpProvider(RPC);
            context.state.web3 = new Web3(provider);

            context.state.bnbPrice = await getBNBPrice(context.state.web3);

            await context.dispatch("tokenLocks/loadLockList");
            await context.dispatch("locks/loadLockList");

            context.dispatch("loadInfo");
        },
        async loadInfo(context){
            let info = await getAggregateInfo(context.state.web3);
            context.state.totalLocks = info.locks;
            context.state.totalTime = (info.time / 31536000).toFixed(2) +  " Years";
        }
    }
});
