<template>
  <div class="flex flex-col justify-between min-h-screen">
    <Navbar />
    <div class="container mx-auto px-4 my-8 lg:my-24">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
};
</script>
<style>
* {
  font-family: "Open Sans", sans-serif;
  text-decoration: none !important;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  margin: 0;
  background: url("./assets/homebg.svg");
  background-color: #1a293e;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

@media (max-width: 1024px) {
  body {
    background-size: 200%;
  }
}

@media (max-width: 768px) {
  body {
    background-size: 300%;
  }
}

.gradient-text {
  background: linear-gradient(to right, #3345f5 0%, #3bc9f5 25%, #3345f5 50%, #3bc9f5 75%, #3345f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700 !important;
  background-size: 400%;
  animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.gradient-border {
  background: linear-gradient(to right, #3345f5 0%, #3bc9f5 100%) !important;
  border-radius: 50px;
  padding: 14px;
  position: relative;
  z-index: 1;
}
.gradient-border::before {
  position: absolute;
  content: "";
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  top: 3px;
  left: 3px;
  border-radius: 50px;
  background-color: #273f59;
  z-index: -1;
}
</style>
