<template>
  <section class="container wow fadeInDown"
      data-wow-duration="0.6s"
      data-wow-delay="0.3s" style="margin-bottom:20px">
      <div
      class="heading"
    >
      <h2 class="pb-2">
        My Locks
      </h2>
    </div>
    <div class="lion">
      <Lionanimate/>
    </div>
  </section>
  
</template>

<script>
import Lionanimate from "@/components/Lionanimate.vue";
export default {
  name: "Head",
  components:{
    Lionanimate
  }
};
</script>

<style scoped>

.lion{
    max-width: 150px;
    margin: 0 auto;
    margin-top: -40px;
    margin-bottom: -20px;
    display: block;
}

</style>
