<template>
  <section class="wow fadeInDown mb-10" data-wow-duration="0.6s" data-wow-delay="0.7s" style="margin-top:0px">
    <div class="bg-gray-900 rounded-2xl overflow-hidden max-w-150 mx-auto border border-gray-700">
      <div class="bg-gray-800 border-b border-gray-700">
        <h3 class="gradient-text w-full py-6 text-center">Create New {{ isLPLock ? "Liquidity Lock" : "Token Lock" }}</h3>
      </div>
      <div class="sm:px-8 px-4 py-8 flex flex-col items-center" v-if="!$store.getters['wallet/isWalletConnected']">
        <Button name="Connect Wallet" class="connectBtn w-full max-w-64"
                @click="$store.dispatch('wallet/connectWallet')"
        />
      </div>
      <div class="sm:px-8 px-4 pb-8" v-else>
        <div>
          <input
            class="bg-locker_primary overline bg-opacity-10 hover:shadow-locker_primary focus:shadow-locker_primary relative mt-8 border-locker_primary transition-all duration-200 placeholder-locker_primary text-locker_primary rounded-full border p-3 w-full text-center"
            placeholder="Enter token address"
            v-model="token"
          />
        </div>
        <div class="space-y-6 flex flex-col">
          <input
            class="bg-locker_primary overline bg-opacity-10 hover:shadow-locker_primary focus:shadow-locker_primary relative mt-4 border-locker_primary transition-all duration-200 placeholder-locker_primary text-locker_primary rounded-full border p-3 w-full text-center"
            placeholder="Amount to lock"
            v-model="amountIn"
          />
          <div class="flex flex-row justify-between" v-if="tokenBalance">
            <span>Balance: </span>
            <span>
              {{ tokenBalance }} {{ tokenSymbol }}
              <span class="text-locker_primary pl-1 cursor-pointer" @click="setMaxIn">(Max)</span>
            </span>
          </div>
          <!--
          <vue3-slider class="slider" v-model="sliderVal" :height="16" trackColor="#0D2C42" color="#3BD0F5" :tooltip="tooltip" :min="min" :max="max" :step="10" :tooltipText="tooltipText + '%'" :orientation="orientation" />
          -->
        </div>

        <div class="caption my-8 bg-gray-800 rounded-lg p-4">
          <span class="overline text-gray-300">Unlock Date and Time</span>
          <div class="flex flex-col lg:flex-row lg:space-x-4">
            <flat-pickr
              v-model="date"
              :config="config"
              class="bg-locker_primary overline bg-opacity-10 hover:shadow-locker_primary text-center focus:shadow-locker_primary relative mt-4 border-locker_primary transition-all duration-200 placeholder-locker_primary text-locker_primary rounded-full border py-3 px-6 w-full cursor-pointer"
              placeholder="Select date and time"
              name="date"
            />
          </div>
        </div>

        <div class="caption my-8 bg-gray-800 rounded-lg p-4" v-if="isLPLock && lpDex">
          <span class="overline text-gray-300">LP Summary</span>
          <div class="flex flex-col space-y-1 mt-4">
            <div class="flex flex-row justify-between">
              <span>DEX</span>
              <span>{{ lpDex }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <span>Token 1</span>
              <span>{{ token0Symbol }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <span>Token 2</span>
              <span>{{ token1Symbol }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <span>Value</span>
              <span>${{ lpValue }}</span>
            </div>
          </div>
        </div>

        <div class="caption my-8 bg-gray-800 rounded-lg p-4">
          <span class="overline text-gray-300">Summary</span>
          <div class="flex flex-col space-y-1 mt-4">
            <div class="flex flex-row justify-between">
              <span>Token</span>
              <span>{{ tokenName ? tokenName : "-" }} {{ tokenName ? "(" + tokenSymbol + ")" : "" }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <span>Amount</span>
              <span>{{ toMinPrecisionString(amountIn, 5) }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <span>Unlock Date</span>
              <span>{{ unlockTimeText }}</span>
            </div>
            <div class="flex flex-row justify-between">
              <span>Fee</span>
              <span>0.1 BNB</span>
            </div>
          </div>
          <div class="mt-3 text-gray-300">Once tokens are locked they cannot be withdrawn under any circumstance until the timer has finished, by locking you agree and understand this requirment</div>
        </div>



        <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
          <div class="caption bg-gray-800 rounded-lg p-6 w-full flex flex-col items-center" v-if="inputPrompt">
            <span class="overline text-gray-300">{{ inputPrompt }}</span>
          </div>
          <button v-wave v-else-if="!isApproved" @click="approveToken" class="p-6 border-2 border-locker_primary text-locker_primary bg-locker_primary bg-opacity-0 hover:bg-opacity-20 rounded-lg w-full font-semibold transition-all duration-200">
            Approve {{ isLPLock ? "LP" : "Tokens" }}
          </button>
          <button v-wave v-else @click="createLock" class="p-6 bg-locker_primary text-gray-900 rounded-lg w-full font-semibold hover:bg-opacity-80 transition-all duration-200">
            Lock
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button.vue";
import slider from "vue3-slider";
import {
  approveMax,
  calculatePrices,
  createLock,
  getPriceAndLPValue,
  getSingleTokenHolderInfo,
  loadLPInfo
} from "@/js/locker";
import {FACTORIES, FACTORY_ADR_LP, FACTORY_ADR_TOKENS} from "@/js/constants";
import {BNtoString, stringToBN, toMinPrecisionString} from "@/js/utilities";

export default {
  components: {
    "vue3-slider": slider,
    Button
  },
  props: ["lockType"],
  data() {
    return {
      sliderVal: 0,
      expand: false,
      showInputs: false,
      height: 10,
      width: 200,
      min: 0,
      max: 100,
      step: 1,
      tooltip: true,
      tooltipText: "%v",
      orientation: "horizontal",
      sticky: false,
      repeat: false,
      flip: false,
      circleOffset: 0,
      config: {
        enableTime: true,
      },

      token: null,
      amountIn: null,
      maxIn: false,

      date: null,

      tokenName: null,
      tokenSymbol: null,
      tokenDecimals: null,
      tokenBalance: null,
      tokenBalanceBN: null,
      allowance: "0",

      lpDex: null,
      token0Symbol: null,
      token1Symbol: null,
      lpValue: null,

      unlockTime: 0,
    };
  },
  computed: {
    factory(){
      return this.isLPLock ? FACTORY_ADR_LP : FACTORY_ADR_TOKENS;
    },
    isTokenLock(){
      return this.lockType === "TOKEN";
    },
    isLPLock(){
      return this.lockType === "LP";
    },
    isApproved(){
      return this.allowance !== "0";
    },
    unlockTimeText(){
      if(!this.date){ return ""; }
      let date = new Date(this.unlockTime * 1000);
      return date.getFullYear()+
          "-"+(date.getMonth()+1)+
          "-"+date.getDate()+
          " "+date.getHours()+
          ":"+date.getMinutes();
    },
    inputPrompt(){
      if(this.token === null || this.tokenName === null){
        return "Please enter the token address";
      }
      if(this.isLPLock && this.lpDex === null){
        return "Please enter a valid LP token or use the token locker instead";
      }
      if(this.amountIn === null || this.amountIn == 0){
        return "Please enter token amount";
      }
      if(parseFloat(this.amountIn) > parseFloat(this.tokenBalance)){
        return "Insufficient Balance";
      }
      if(this.date === null){
        return "Please enter unlock date";
      }
      if(this.unlockTime < Date.now() / 1000){
        return "Please enter a date in the future";
      }
    }
  },
  watch: {
    amountIn: function(){
      this.maxIn = false;
    },
    date(){
      this.unlockTime = Math.floor(Date.parse(this.date) / 1000);
    },
    token: async function(){
      this.amountIn = 0;
      this.sliderVal = 0;

      this.tokenName = null;
      this.tokenSymbol = null;
      this.tokenDecimals = null;
      this.allowance = "0";
      this.lpDex = null;
      this.token0Symbol = null;
      this.token1Symbol = null;
      this.lpValue = null;

      let address = this.token.match(/[0-9xA-Za-z]{42}/);
      if(address){
        address = this.$store.state.web3.utils.toChecksumAddress(address[0]);
        this.token = address;

        let info = await getSingleTokenHolderInfo(this.$store.state.web3, address, this.$store.getters["wallet/address"], this.factory);
        this.tokenName = info.name;
        this.tokenSymbol = info.symbol;
        this.tokenDecimals = info.decimals;
        this.tokenBalance = BNtoString(info.balance, info.decimals);
        this.tokenBalanceBN = info.balance;
        this.allowance = info.allowance;

        if(this.isTokenLock){

        }else{
          let lpInfo = await loadLPInfo(this.$store.state.web3, address);
          this.lpDex = FACTORIES.hasOwnProperty(lpInfo.pairInfo.factory) ? FACTORIES[lpInfo.pairInfo.factory] : "Unknown";
          this.token0Symbol = lpInfo.token0.symbol;
          this.token1Symbol = lpInfo.token1.symbol;

          let priceInfo = calculatePrices(this.$store.state.bnbPrice, lpInfo.token0.adr, lpInfo.token0.decimals, lpInfo.pairInfo.reserves0, lpInfo.token1.adr, lpInfo.token1.decimals, lpInfo.pairInfo.reserves1);
          this.lpValue = priceInfo.value;

        }
      }
    }
  },
  methods: {
    setMaxIn(){
      this.amountIn = this.tokenBalance;
      this.maxIn = true;
    },
    toMinPrecisionString(v,n){
      return toMinPrecisionString(v,n);
    },
    async approveToken(){
      await approveMax(this.$store.getters["wallet/web3"], this.$store.getters["wallet/address"], this.token, this.factory);
      let info = await getSingleTokenHolderInfo(this.$store.state.web3, this.token, this.$store.getters["wallet/address"], this.factory);
      this.allowance = info.allowance;
    },
    async createLock(){
      let amount = this.maxIn ? this.tokenBalanceBN : stringToBN(this.amountIn, this.tokenDecimals);
      let lock = await createLock(this.factory, this.$store.getters["wallet/web3"], this.$store.getters["wallet/address"], this.token, amount, this.unlockTime);
      if(lock){
        this.$router.push({path: "/lock/"+lock.events[0].address});
      }
    }
  }
};
</script>

<style scoped>
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.checkbox {
  --border-color: #d2d6dc;
  --color: #3f83f8;
  display: flex;
  align-items: center;
}
.checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  color: #fff;
  background-color: #3bd0f51f;
  border-color: #3bd0f5;
  border-width: 1px;
  border-radius: 0.25rem;
  border-style: solid;
}
.checkbox input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
  border-color: transparent;
  background-color: #3bd0f5;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
@media not print {
  .checkbox input[type="checkbox"]::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}
.checkbox input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
  border-color: #a4cafe;
}
.checkbox input[type="checkbox"]:checked:focus {
  border-color: transparent;
}
.checkbox label {
  margin-left: 0.5rem;
  display: block;
  line-height: 1.25rem;
}

input[type="date"],
input[type="time"] {
  display: block;

  /* Solution 1 */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 32px;
  width: 100%;
}
</style>
