import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";

import "./assets/css/main.css";

// Datepicker
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

// Button "ripple" effect
import VWave from "v-wave";

store.commit("initialize", store.dispatch("initialize"));

createApp(App)
    .use(router)
    .use(store)
    .use(VueFlatPickr)
    .use(VWave, {
        initialOpacity: 0.3,
        easing: "ease-in",
    })
    .mount("#app");
