<template>
  <section class="container">
    <div class="text-center flex place-self-center flex-col w-full wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0s">
      <h1 class="gradient-text">The Locker of Ananke</h1>
      <h4 class="overline mt-3 text-white">The Safest Place to Secure Your Tokens & Liquidity</h4>
    </div>

    <div class="flex flex-col lg:space-y-0 space-y-4 lg:flex-row justify-between items-center">

      <div class="flex items-center place-content-center lg:order-1 order-2 bg-opacity-90 border-gray-700 border-2 bg-gray-900 w-full rounded-2xl overflow-hidden py-10 wow fadeInLeft" data-wow-duration="0.3s" data-wow-delay="0.4s">
        <div class="flex items-center flex-col">
          <div class="p-3 bg-gradient-to-b from-gray-700 to-gray-800 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="64px" height="64px">
              <defs>
                <linearGradient id="i77_QV4sKrSMkfzuwUz6la" x1="256" x2="256" y1="15.117" y2="495.177" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#00efd1" />
                  <stop offset="1" stop-color="#00acea" />
                </linearGradient>
              </defs>
              <g data-name="&lt;Group&gt;">
                <path
                  fill="url(#i77_QV4sKrSMkfzuwUz6la)"
                  d="M481.035,242.031,410.023,99.646l-.627-1.185A9.935,9.935,0,0,0,400.452,93h0c-1.719,0-3.359,0-4.809,1H303.265C298.389,73,278.94,56.133,256,56.133S213.611,73,208.735,94H116.36a8.1,8.1,0,0,0-4.809-1.2,10.026,10.026,0,0,0-8.949,5.565L30.965,242.016a10.006,10.006,0,0,0-7.714,9.735c0,43.808,39.611,79.451,88.3,79.451,48.66,0,88.248-35.638,88.248-79.446a10.061,10.061,0,0,0-7.653-9.768L128.341,114h80.081A48.805,48.805,0,0,0,246,152.2V336.774C211.779,340.677,184.73,364,180.945,394H157.432c-5.523,0-10.432,4.33-10.432,9.853v41.9A10.591,10.591,0,0,0,157.432,456H354.568A10.591,10.591,0,0,0,365,445.752v-41.9c0-5.523-4.909-9.853-10.432-9.853H331.055c-3.785-30-30.834-53.323-65.055-57.226V152.2A48.805,48.805,0,0,0,303.578,114H383.66L319.854,241.989a10.029,10.029,0,0,0-7.653,9.74c0,43.808,39.588,79.463,88.248,79.463,48.689,0,88.3-35.633,88.3-79.441A9.989,9.989,0,0,0,481.035,242.031ZM111.551,311.448C77.805,311.448,49.7,290,44.218,262H178.833C173.358,290,145.271,311.448,111.551,311.448ZM169.651,242H53.45l58.1-116.495ZM345,414v22H167V414Zm-34.188-20H201.188c4.243-21,27.2-37.664,54.812-37.664S306.569,373,310.812,394ZM256,133.238a28.536,28.536,0,0,1-28.492-28.127c.028-.281.045-.564.049-.847a28.446,28.446,0,0,1,56.886,0c0,.284.021.569.05.852A28.529,28.529,0,0,1,256,133.238ZM400.448,125.5,458.55,242h-116.2Zm0,185.946c-33.72,0-61.807-21.448-67.282-49.448H467.782C462.3,290,434.2,311.448,400.449,311.448Z"
                  data-name="&lt;Compound Path&gt;"
                />
              </g>
            </svg>
          </div>
          <h2 class="gradient-text my-2">{{ $store.state.totalLocks }}</h2>
          <h3 class="overline">Total projects locked</h3>
        </div>
      </div>

      <div class="w-full place-content-center flex lg:order-2 order-1 relative lg:h-125 wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.4s">
        <Lionanimate />
      </div>

      <div class="flex items-center place-content-center lg:order-3 order-3 bg-opacity-90 border-gray-700 border-2 bg-gray-900 w-full rounded-2xl overflow-hidden py-10 wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.4s">
        <div class="flex items-center flex-col">
          <div class="p-3 bg-gradient-to-b from-gray-700 to-gray-800 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="64px" height="64px">
              <defs>
                <linearGradient id="3lUCgnNDMbqv09vTMcXPLa" x1="256.001" x2="256.001" y1="475.356" y2="12.307" gradientTransform="matrix(1 0 0 -1 0 514)" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#00efd1" />
                  <stop offset="1" stop-color="#00acea" />
                </linearGradient>
              </defs>
              <path
                fill="url(#3lUCgnNDMbqv09vTMcXPLa)"
                d="M373.905,119.984V65h30.2a10,10,0,0,0,0-20H107.9a10,10,0,1,0,0,20h25.4v54.164a73.558,73.558,0,0,0,24.415,54.7l86.027,78.9-82.4,78.93A73.73,73.73,0,0,0,138.1,385.312V447H107.9a10,10,0,1,0,0,20H404.106a10,10,0,0,0,0-20H378.7V386.132a73.558,73.558,0,0,0-24.415-54.7l-86.027-78.9,82.4-78.928A73.729,73.729,0,0,0,373.905,119.984ZM194.452,447l61.025-70.968L316.5,447ZM340.9,346.292a53.54,53.54,0,0,1,17.806,39.84V447H342.88l-79.82-92.826a10,10,0,0,0-15.164,0L168.075,447H158.1V385.312a53.661,53.661,0,0,1,16.951-39.058c.029-.027.059-.055.087-.083l81.016-77.6,84.659,77.643C340.837,346.239,340.866,346.266,340.9,346.292Zm-3.942-187.25c-.029.027-.059.055-.087.083l-81.016,77.6-84.659-77.643L171.1,159a53.54,53.54,0,0,1-17.806-39.84V65H353.9v54.984A53.652,53.652,0,0,1,336.954,159.042Z"
              />
            </svg>
          </div>
          <h2 class="gradient-text my-2">{{ $store.state.totalTime }}</h2>
          <h3 class="overline">Total current lockup time</h3>
        </div>
      </div>
    </div>
    <div class="flex lg:space-x-4 lg:space-y-0 space-y-4 lg:pt-0 pt-4 lg:flex-row flex-col justify-between w-full wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.6s">
      <Lockers name="Explore Locks" btnname="EXPLORE" routername="explore" />
      <Lockers name="Lock Liquidity" btnname="LOCK" routername="liquiditylocker" />
      <Lockers name="Lock Tokens" btnname="LOCK" routername="tokenlocker" />
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Lionanimate from "@/components/Lionanimate.vue";
import Lockers from "@/components/Lockers.vue";
export default {
  name: "Gateway",
  components: {
    Lionanimate,
    Lockers,
  },
};
</script>
