import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3 from "web3";
import {BNtoString} from "@/js/utilities.js";

export default {
    namespaced: true,
    state: () => ({
        web3: null,
        address: null,
        balance: null,
        tokens: [],
        balances: {},
    }),
    mutations: {
        connectWeb3(state, web3){
            state.web3 = web3;
        },
        loadAccount(state, {address, balance}){
            state.address = address;
            state.balance = balance;
            state.tokens = [];
            state.balances = {};
            window.localStorage.setItem("connectWallet", "true");
        },
        disconnectWallet(state){
            state.web3 = null;
            state.address = null;
            state.balance = null;
            state.tokens = [];
            state.balances = {};
            window.localStorage.setItem("connectWallet", "");
            location.reload();
        }
    },
    getters: {
        isWalletConnected(state, getters){
            return getters.address !== null;
        },
        web3(state){
            return state.web3;
        },
        address(state){
            return state.address;
        },
        addressPreviewShort(state){
            return state.address.substr(0,6);
        },
        addressPreviewLong(state){
            return state.address.substr(0,5) + "..." + state.address.substr(-4);
        },
        balance(state){
            return BNtoString(state.balance, 18);
        },
        balanceBN(state){
            return state.balance;
        },
    },
    actions: {
        async loadAccount({getters, commit}){
            let accounts = await getters.web3.eth.getAccounts();
            let address = accounts[0];
            let balance = await getters.web3.eth.getBalance(address);

            commit("loadAccount", {address, balance});
        },
        async connectWallet({state, commit, dispatch, rootState}){
            const providerOptions = {
                walletconnect: {
                    package: WalletConnectProvider, // required
                    options: {
                        rpc: {
                            56: rootState.RPC
                        }
                    }
                }
            }

            const web3Modal = new Web3Modal({
                providerOptions, // required
                cacheProvider: false, // optional
            })

            let provider = await web3Modal.connect();

            provider.on('accountsChanged', () => {
                dispatch("loadAccount");
            })

            provider.on('disconnect', () => {
                commit("disconnectWallet");
            })

            let web3 = new Web3(provider);
            commit("connectWeb3", web3);
            await dispatch("loadAccount");

            dispatch("locks/loadHolderLocks", state.address, {root:true});
            dispatch("tokenLocks/loadHolderLocks", state.address, {root:true});

            provider.request({ method: 'eth_chainId' }).then(async ()=>{
                try {
                    await provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x38' }],
                    });
                } catch (error) {
                    if (error.code === 4902) {
                        try {
                            await provider.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: '0x38', // A 0x-prefixed hexadecimal string
                                    chainName: 'Binance Smart Chain',
                                    nativeCurrency: {
                                        name: "BNB",
                                        symbol: "BNB", // 2-6 characters long
                                        decimals: 18,
                                    },
                                    rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
                                    blockExplorerUrls: ["https://bscscan.com/"],
                                }],
                            });
                        } catch (addError) {
                            window.alert("Please switch to BSC network");
                        }
                    }else{
                        window.alert("Please switch to BSC network");
                    }
                }
            })
        }
    }
}