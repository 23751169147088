<template>
  <div>
    <Head :lock="lock"/>
    <div class="container flex lg:flex-row flex-col wow mt-8 fadeInDown lg:space-y-0 space-y-4 lg:space-x-4 wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.9s">
      <Lockers name="Lock Liquidity" btnname="LOCK" routername="liquiditylocker" />
      <Lockers name="Lock Tokens" btnname="LOCK" routername="tokenlocker" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Rows from "./components/Rows.vue";
import Head from "./components/Head.vue";
import Lockers from "@/components/Lockers.vue";
import {getLockFactory} from "@/js/locker";
import {FACTORY_ADR_LP} from "@/js/constants";
export default {
  name: "Explorecard",
  components: {
    Rows,
    Head,
    Lockers,
  },
  props: ["lock"],
};
</script>

<style></style>
