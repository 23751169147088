<template>
  <div class="flex lg:flex-row flex-col justify-between items-center lg:space-y-0 space-y-4 lg:bg-transparent bg-gray-800 lg:p-0 p-4 rounded-lg lg:mx-0 mx-4">
    <div class="tokenNameCell lg:pb-0 pb-4 flex items-center">
      <div class="relative flex-none h-6 w-10 items-center">
        <img class="w-6 h-6 absolute border-locker_primary border-2 rounded-full" src="@/assets/icons/olympus.svg" alt="Logo" />
        <img class="w-6 h-6 absolute left-4 shadow-smooth z-10 border-locker_primary border-2 rounded-full" src="@/assets/icons/bnb.svg" alt="Logo" />
      </div>
      <span class="text-sm inline-block ml-2" v-if="isLPLock">{{ info.token0.symbol }} / {{ info.token1.symbol }} LP</span>
      <span class="text-sm inline-block ml-2" v-else>{{ info.token.symbol }} </span>
    </div>

    <div class="text-sm justify-between w-full flex liqLockedCell">
      <span class="lg:hidden inline-block text-gray-400 pr-2">
        Supply Locked
      </span>
      <span>{{ info.amountLocked}} ({{ info.supplyLocked }})</span>
    </div>
    <div class="text-sm justify-between w-full flex valueLockedCell">
      <span class="lg:hidden inline-block text-gray-400 pr-2">Locked Value</span><span>${{ info.lockedValue }}</span>
    </div>
    <div class="text-sm justify-between w-full flex nextUnlockCell">
      <span class="lg:hidden inline-block text-gray-400 pr-2">Unlock</span>
      <div class="w-full caption" v-if="info.unlocked">Unlocked</div>
      <div class="countdown w-full caption" v-else>
        <div class="time text-gray-400 caption uppercase">
          {{ info.timeLeft.days > 0 ? info.timeLeft.days+"D" : ""}}
          {{ info.timeLeft.hours > 0 ? info.timeLeft.hours+"H" : ""}}
          {{ info.timeLeft.minutes > 0 ? info.timeLeft.minutes+"M" : ""}}
        </div>
        <div class="h-2 bg-gray-700 rounded-full w-full">
          <div class="w-full rounded-full bg-gradient-to-r to-locker_primary-dark h-full from-locker_primary" :style="'width:'+info.timePercentThrough"></div>
        </div>
      </div>
    </div>
    <div class="text-sm actionColCell hover:opacity-50 transition-opacity duration-200 text-locker_primary justify-end flex items-center">
      <router-link :to="'/lock/'+lock">View</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import ItemComp from "@/components/Itemc.vue"
import Timer from "@/components/Timer.vue";
export default {
  name: "Rowsingle",
  props: ["lock", "lockType"],
  components: {
    Timer,
  },
  computed: {
    info(){
      return this.$store.getters[this.isLPLock ? 'locks/info' : 'tokenLocks/info'](this.lock);
    },
    isTokenLock(){
      return this.lockType === "TOKEN";
    },
    isLPLock(){
      return this.lockType === "LP";
    }
  }
};
</script>

<style scoped>
.row_head button {
  padding: 4px;
  background: transparent;
  position: relative;
}

.progress {
  padding: 0;
  width: 90%;
  height: 10px;
  overflow: hidden;
  background: #3a546b;
  border-radius: 12px;
  margin: 5px 0 !important;
  min-width: 140px;
}

.time {
  display: flex;
}
</style>
