<template>
  <section class="container wow fadeInDown relative mt-10" data-wow-duration="0.3s" data-wow-delay="0.8s">
    <div class="relative w-full mb-10">
      <div class="bg-gray-900 border border-gray-700 rounded-2xl overflow-hidden">
        <div class="bg-gray-800 shadow-lg border-b border-gray-700">
          <div class="flex-row hidden lg:flex justify-between h-12  items-center pl-6 pr-9">
            <div class="lg:flex hidden overline text-gray-400 tokenName">Name</div>
            <div class="lg:flex hidden overline text-gray-400 liqLocked">Supply Locked</div>
            <div class="lg:flex hidden overline text-gray-400 valueLocked">Locked Value</div>
            <div class="lg:flex hidden overline text-gray-400 nextUnlock">Unlock</div>
            <div class="w-full flex justify-end actionCol"></div>
          </div>
        </div>
        <div class=" h-142 overflow-auto lg:space-y-8 space-y-6 px-2 py-6 lg:py-6 lg:px-6">
          <div v-for="lock in $store.getters['locks/holderLocks']($store.getters['wallet/address'])">
            <Rowsingle v-if="$store.getters['locks/isLoaded'](lock)" :lock="lock" lockType="LP"/>
          </div>
          <div v-for="lock in $store.getters['tokenLocks/holderLocks']($store.getters['wallet/address'])">
            <Rowsingle v-if="$store.getters['tokenLocks/isLoaded'](lock)" :lock="lock" lockType="TOKEN"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Rowsingle from "@/views/Explore/components/Rowsingle";

export default {
  name: "Rows",
  data: function() {
    return {
      lockMode: 0,
      modeActive: "bg-locker_primary shadow-locker_primary border-locker_primary text-locker_primary-xtra_dark",
      modeInactive: "bg-gray-900 text-gray-400 border-gray-600",
    };
  },
  components: {
    Rowsingle,
  },
};
</script>
